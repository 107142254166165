import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useAtom } from 'jotai'
import { trim } from 'lodash-es'
import { useCallback, useEffect } from 'react'
import { UAParser } from 'ua-parser-js'

import { webEnvState } from '@/atoms/webEnvState'
import { CONFIG } from '@/constants/config'
import { init as datadogInit } from '@/libs/datadog'
import { init as mixpanelInit } from '@/libs/mixpanel'
import { isClient } from '@/utils/compare'

type useClientInitProps = {
  appVersion?: string
}

const useClientInit = ({ appVersion }: useClientInitProps) => {
  const [webEnv, setWebEnv] = useAtom(webEnvState)

  const appClientInit = useCallback(async () => {
    if (isClient) {
      // RTG 옵션 Check 시 해당 서버(PRD -> RTG, STG -> DTG)로 리다이렉트 처리
      const serverType = await TmapApp.getUserSetting({ key: 'local.server_type' })
      if (serverType === 'rtg') {
        const { href } = window.location
        if (href.startsWith('https://uw.')) {
          window.location.replace(href.replace('https://uw.', 'https://uwrtg.'))
          return
        }

        if (href.startsWith('https://uwstg.')) {
          window.location.replace(href.replace('https://uwstg.', 'https://uwdtg.'))
          return
        }
      }
    }
  }, [])

  // WebEnv Init.
  useEffect(() => {
    if (isClient) {
      const appName = CONFIG.APP_NAME
      const { userAgent } = navigator
      const ua = UAParser()
      const userAgentLower = userAgent.toLowerCase()
      const device = userAgent.replace(/.*?\((.+?)\).*/, '$1')
      const isInApp = userAgentLower.indexOf(appName.toLowerCase()) > -1

      let isIOS = false
      let isAndroid = false
      let appVersion = '0.0.0'
      if (isInApp) {
        const inAppSuffixIndex = userAgentLower.indexOf(appName.toLowerCase())
        const inAppSuffix = userAgent.substring(inAppSuffixIndex)
        // Tmap/SK/Android/8.0.0/
        // Tmap/iOS/6.6.0
        const parts = trim(inAppSuffix, '/').split('/')

        if (userAgentLower.indexOf('ios') !== -1) {
          isIOS = true
        } else {
          isAndroid = true
        }

        appVersion = parts[isIOS ? 2 : 3]
      } else {
        isIOS = /ipad|iphone/i.test(navigator.userAgent)
        isAndroid = /android/i.test(navigator.userAgent)
      }

      setWebEnv({
        appName,
        appVersion,
        osName: ua.os.name || '',
        osVersion: ua.os.version || '',
        device: trim(device.split(';').slice(1).join(';')) || '',
        model: ua.device.model || '',
        userAgent: navigator.userAgent,
        vendor: ua.device.vendor || '',
        isIOS,
        isAndroid,
        isInApp,
      })
    }
  }, [setWebEnv])

  // Mixpanel & Datadog Init.
  useEffect(() => {
    if (webEnv) {
      mixpanelInit(webEnv)
      datadogInit({ appVersion })
    }
  }, [webEnv, appVersion])

  useEffect(() => {
    appClientInit()
  }, [appClientInit])

  return {}
}

export default useClientInit
