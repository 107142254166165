import { ReactNode, createContext } from 'react'

export type ConfirmOption = {
  content: ReactNode;
  okText?: string;
  cancelText?: string;
};

type Type = {
  confirm: (option: ConfirmOption) => Promise<boolean>;
};

const ConfirmContext = createContext<Type>({
  confirm: () =>
    new Promise((_, reject) => {
      reject()
    }),
})

export default ConfirmContext
