import { API } from '@/constants/api'
import { METHOD, request } from '@/utils/api'

import { AccessTokenResponse } from './types'

export type PostAccessTokenResponse = AccessTokenResponse

export const postAccessToken = (access_key: string) => {
  return request<PostAccessTokenResponse>({
    url: `${API.ENDPOINT.UW_TMAP}/auth/accessToken`,
    method: METHOD.POST,
    data: {
      access_key,
    },
  })
}
