import { AxiosError } from 'axios'

import { postAccessToken } from '@/apis/userweb/postAccessToken'
import { API, SQUARE_MOCKUP } from '@/constants/api'
import { LOCAL_STORAGE_KEY } from '@/constants/key'

import {
  METHOD,
  getAccessKey,
  getDefaultFrontmanHeaders,
  getDefaultZendeskAuthHeaders,
  getDefaultZendeskHeaders,
  setZendeskAccessToken,
} from './config'
import { RequestCustomOption, request } from './request'

type FrontmanAPI = {
  method: METHOD
  isProxy?: boolean
  path: string
  query?: Record<string, any>
  headers?: Record<string, string>
  body?: any
  signal?: AbortSignal
  timeout?: number
  useAuth?: boolean
} & RequestCustomOption

const getUrl = (path: string, query?: Record<string, any>) => {
  const pathString = path[0] === '/' ? path : `/${path}`
  const params = new URLSearchParams(query)
  const queryString = params.toString()

  return `${API.ENDPOINT.FRONTMAN}${pathString}${queryString}`
}

export const frontmanApi = <T = any>(args: FrontmanAPI) => {
  const headers = args.headers || {}

  return request<T>(
    {
      method: args.method,
      url: getUrl(args.isProxy ? `/proxy${args.path}` : args.path),
      params: args.query,
      headers: {
        ...getDefaultFrontmanHeaders(),
        ...headers,
      },
      data: args.body,
      signal: args.signal,
      timeout: args.timeout,
    },
    {
      brand: args.brand,
      isErrorActionDisabled: args.isErrorActionDisabled,
    }
  )
}

export const frontmanZendeskApi = async <T = any>(args: FrontmanAPI) => {
  try {
    const headers = args.headers || {}

    // MOCKUP MODE
    if (
      localStorage.getItem(LOCAL_STORAGE_KEY.SQUARE_MOCKUP_API_MODE) === 'true' &&
      SQUARE_MOCKUP[args.method][args.path]
    ) {
      return request<T>(
        {
          method: args.method,
          url: `${API.ENDPOINT.SQUARE_MOCKUP}${SQUARE_MOCKUP[args.method][args.path]}`,
          params: args.query,
          headers: {
            ...getDefaultFrontmanHeaders(),
            ...headers,
          },
          data: args.body,
          signal: args.signal,
          timeout: args.timeout,
        },
        {
          brand: args.brand,
          isErrorActionDisabled: args.isErrorActionDisabled,
        }
      )
    }

    const path = args.brand
      ? `/dynamic/zendesk/${args.brand}/api/v2${args.path}`
      : `/zendesk/api/v2${args.path}`

    if (args.useAuth) {
      const zendeskToken = localStorage.getItem(LOCAL_STORAGE_KEY.ZENDESK_ACCESS_TOKEN)
      const userKey = localStorage.getItem(LOCAL_STORAGE_KEY.ZENDESK_USER_KEY)

      if (zendeskToken && userKey && !getDefaultFrontmanHeaders().authorization) {
        setZendeskAccessToken({
          access_token: zendeskToken,
          userKey,
        })
      } else {
        const ak = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_KEY) || (await getAccessKey())
        if (ak) {
          const newZendeskToken = await postAccessToken(ak)
          setZendeskAccessToken(newZendeskToken)
        } else {
          throw new AxiosError('Access Key is not found')
        }
      }

      return frontmanApi<T>({
        ...args,
        path,
        headers: {
          ...getDefaultZendeskAuthHeaders(),
          ...headers,
        },
      })
    } else {
      return frontmanApi<T>({
        ...args,
        path,
        headers: {
          ...getDefaultZendeskHeaders(),
          ...headers,
        },
      })
    }
  } catch (err) {
    return Promise.reject(err)
  }
}
