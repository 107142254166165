import { datadogRum } from '@datadog/browser-rum'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config'

import { CONFIG, TARGET } from '@/constants/config'

import { firebaseApp } from './firebase'

type initProps = {
  appVersion?: string
}

export const init = async ({ appVersion }: initProps) => {
  const defaultConfig = {
    applicationId: '4dc4cca2-4857-45c0-9c5d-97a008d995cb',
    clientToken: 'pub68921006f33cfbd18804936ec92fbc26',
    site: 'datadoghq.com',
    service: 'tmap-cs',
    env: CONFIG.TARGET,
    version: appVersion,
    trackUserInteractions: true,
  }
  const defaultSessionSampleRate = CONFIG.TARGET === TARGET.PRD ? 5 : 100
  const remoteConfig = getRemoteConfig(firebaseApp)
  remoteConfig.settings.minimumFetchIntervalMillis = 0 // 설정을 따로 하지 않으면 기본 12시간(43200000)

  Promise.all([TmapApp.getDeviceId(), fetchAndActivate(remoteConfig)]).then(([targetDeviceId]) => {
    const dataDogSessionSampleRate = getValue(remoteConfig, 'cs_datadog_sample_rate').asNumber()
    const dataDogSessionReplayRate = getValue(remoteConfig, 'cs_datadog_session_replay').asNumber()
    const targetList = getValue(remoteConfig, 'cs_datadog_target_list').asString()

    const datadogTargetList = targetList.split(',')
    const isTargetUser = datadogTargetList.findIndex((target) => target === targetDeviceId) !== -1

    if (isTargetUser) {
      datadogRum.init({
        ...defaultConfig,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
      })
      datadogRum.startSessionReplayRecording()
    } else {
      datadogRum.init({
        ...defaultConfig,
        sessionSampleRate: dataDogSessionSampleRate
          ? Number(dataDogSessionSampleRate)
          : defaultSessionSampleRate,
        sessionReplaySampleRate: dataDogSessionReplayRate ? Number(dataDogSessionReplayRate) : 0,
      })
    }

    if (isTargetUser || (TmapApp.env.isInApp && dataDogSessionReplayRate)) {
      datadogRum.startSessionReplayRecording()
    }
  })
}
