import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { gte } from 'semver'

import { ROUTER } from '@/constants/router'
import { movePage } from '@/utils/move'
import { getSchemeJsonData } from '@/utils/parse'

const useLink = () => {
  const router = useRouter()

  const openSchemeLink = useCallback(
    (_href: string | null | undefined) => {
      const href = _href?.replace(/\n|&nbsp;|&lt;|&gt;/g, '')

      if (href) {
        if (TmapApp.env.isInApp) {
          const isVerOpenServiceByName = gte(TmapApp.env.appVersion, '8.13.0')

          if (href.indexOf('tmap://cs') > -1) {
            // 고객센터 스킴
            const extra = getSchemeJsonData(href)
            const pageId = extra?.pageid ?? 'faq'
            const extraQuery = JSON.parse((extra?.extra as string) ?? '{}') || {}
            const qnaViewId = extraQuery.id ?? ''
            const query: Record<string, string> = {}

            switch (pageId) {
              case 'faq':
                if (extraQuery.categoryName || extraQuery.categoryId) {
                  movePage({
                    pathname: ROUTER.CATEGORY,
                    query: { ...extraQuery },
                  })
                } else {
                  movePage({
                    pathname: ROUTER.MAIN,
                    query: { ...extraQuery },
                  })
                }
                break
              case 'qna':
                router.push({
                  pathname: ROUTER.QNA.INQUIRY,
                  query: {
                    isSkipPreview: false,
                    ...extraQuery,
                  },
                })
                break
              case 'qna.view':
                if (qnaViewId) {
                  movePage({
                    pathname: ROUTER.QNA.DETAIL(qnaViewId),
                    query: { ...extraQuery },
                  })
                }
                break
              default:
                if (pageId) {
                  query.pageid = pageId
                }
                if (extraQuery) {
                  query.extra = JSON.stringify({ ...extraQuery })
                }
                movePage({
                  pathname: ROUTER.MAIN,
                  query,
                })
                break
            }
            return
          }

          if (href.indexOf('tmap://') > -1 && isVerOpenServiceByName) {
            TmapApp.openServiceByName({
              serviceName: href.split('?')[0].split('://')[1],
              jsonData: getSchemeJsonData(href),
            })
          }
        } else {
          alert('스킴 링크는 모바일 앱에서만 열 수 있습니다.')
        }
      }
    },
    [router]
  )

  const openBrowserLink = useCallback((href: string | null | undefined) => {
    if (href) {
      if (TmapApp.env.isInApp) {
        TmapApp.openBrowser({ url: href })
      } else {
        window.open(href, '_blank')
      }
    }
  }, [])

  return {
    openSchemeLink,
    openBrowserLink,
  }
}

export default useLink
