import { Global, ThemeProvider } from '@emotion/react'
import { ReactNode, useState } from 'react'

import global from '@/styles/global'
import reset from '@/styles/reset'
import { mode } from '@/styles/theme'

function CustomThemeProvider({ children }: { children: ReactNode }) {
  // const [theme, setTheme] = useState(mode.light)
  const [theme] = useState(mode.light)
  // FIXME: 조건 부 Theme 설정
  // useEffect(() => {
  //   if (false) {
  //     setTheme(mode.dark)
  //   }
  // }, [])

  return (
    <ThemeProvider theme={theme}>
      <Global styles={[reset, global(theme)]} />
      {children}
    </ThemeProvider>
  )
}

export default CustomThemeProvider
