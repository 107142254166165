import { atom } from 'jotai'

export type WebEnv = {
  appName: string;
  userAgent: string;
  isInApp: boolean;
  isIOS: boolean;
  isAndroid: boolean;
  appVersion: string;
  osName: string;
  osVersion: string;
  device: string;
  model: string;
  vendor: string;
};

export const webEnvState = atom<WebEnv | null>(null)
