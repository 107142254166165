import { css, Theme } from '@emotion/react'

import { COLOR } from './color'
import { FontSizeType } from './theme'

export const fontSize = (theme: Theme, size: FontSizeType) => css`
  font-size: ${theme.font[size].size};
  line-height: ${theme.font[size].lineHeight};
`

export const ellipsis = () => css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const textHidden = () => css`
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
`

export const touchGuide = (props?: {
  borderRadius?: string
  top?: number
  left?: number
  right?: number
  bottom?: number
  opacity?: number
  backgroundColor?: string
  zIndex?: number
}) => {
  const {
    borderRadius = '',
    top = 0,
    left = 0,
    right = 0,
    bottom = 0,
    backgroundColor = COLOR.touch.overlay,
    zIndex = 99,
  } = props || {}
  return css`
    &:active::before {
      content: '';
      position: absolute;
      top: ${top}px;
      left: ${left}px;
      right: ${right}px;
      bottom: ${bottom}px;
      pointer-events: none;
      background-color: ${backgroundColor};
      ${!!borderRadius.length && `border-radius: ${borderRadius};`}
      z-index: ${zIndex};
    }
  `
}
