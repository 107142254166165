import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { COLOR } from '@/styles/color'

interface OverlayProps {
  animationDuration?: number;
  noAnimate?: boolean;
  opacity?: number;
  zIndex?: number;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Overlay = styled.div<OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ zIndex, theme }) => zIndex ?? theme.zIndex.overlay};
  background-color: ${({ opacity }) => {
    return opacity ? `rgba(0, 0, 0, ${opacity})` : `${COLOR.gray.color.wb.transparent[700]}`
  }};
  ${({ noAnimate = true, animationDuration = 200 }) =>
    !noAnimate &&
    css`
      animation: ${fadeIn} ${animationDuration / 1000}s forwards ease-out;
    `}
`

export default Overlay
