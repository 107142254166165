const ROOT = '/m/cs'

const getPath = (path: string) => `${ROOT}${path}`

export const ROUTER = {
  MAIN: ROOT,
  ERROR: (status: number) => getPath(`/error/${status}`),
  FAQ: (id: number) => getPath(`/faq/${id}`),
  CATEGORY: getPath('/category'),
  QNA: {
    INQUIRY: getPath('/qna'),
    INQUIRY_END: getPath('/qna/end'),
    LIST: getPath('/qna/list'),
    DETAIL: (id: number) => getPath(`/qna/${id}`),
  },
} as const

export const PAGE_ID = {
  MAIN: 'faq',
  CATEGORY: 'faq',
  FAQ: 'faq.view',
  SEARCH: 'search',
  QNA: 'qna',
  QNA_DETAIL: 'qna.view',
}
