import { Theme, ThemeMode } from '@emotion/react'

import { COLOR } from './color'

export type FontSizeType = 9 | 10 | 11 | 12 | 14 | 15 | 16 | 18 | 20 | 24
type FontOptionType = { size: string; lineHeight: string }

declare module '@emotion/react' {
  export interface ThemeMode {
    font: Record<FontSizeType, FontOptionType>
    zIndex: {
      header: number
      headerExtra: number
      bottomCTA: number
      overlay: number
      bottomSheet: number
      portalAbsolute: number
    }
    background: string
    text: string
  }

  export interface Theme extends ThemeMode {}
}

const light: ThemeMode = {
  font: {
    9: {
      size: '9px',
      lineHeight: '13px',
    },
    10: {
      size: '10px',
      lineHeight: '16px',
    },
    11: {
      size: '11px',
      lineHeight: '16px',
    },
    12: {
      size: '12px',
      lineHeight: '18px',
    },
    14: {
      size: '14px',
      lineHeight: '20px',
    },
    15: {
      size: '15px',
      lineHeight: '22px',
    },
    16: {
      size: '16px',
      lineHeight: '24px',
    },
    18: {
      size: '18px',
      lineHeight: '26px',
    },
    20: {
      size: '20px',
      lineHeight: '28px',
    },
    24: {
      size: '24px',
      lineHeight: '32px',
    },
  },
  zIndex: {
    header: 100,
    headerExtra: 150,
    bottomCTA: 200,
    overlay: 1000,
    bottomSheet: 1100,
    portalAbsolute: 1200,
  },
  background: COLOR.gray.color.wb[0],
  text: COLOR.gray.color.gray[800],
}

// FIXME: dark mode
const dark: ThemeMode = { ...light }

export const mode: {
  light: Theme
  dark: Theme
} = {
  light: { ...light },
  dark: { ...dark },
}
