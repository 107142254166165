import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import Cookies from 'js-cookie'
import mixpanel from 'mixpanel-browser'

import { WebEnv } from '@/atoms/webEnvState'
import { CONFIG, TARGET } from '@/constants/config'
import { COOKIE } from '@/constants/key'

type MixpanelEvent = {
  pageId?: string
  eventName?: string
  customs?: object
}

let mixpanelInit = false
const mixpanelEventQueue: MixpanelEvent[] = []

export const init = async ({ model, osVersion }: WebEnv) => {
  let carrierName = ''
  let deviceId = ''
  let sessionId = ''
  let euk = ''

  if (TmapApp.env.isInApp) {
    const [appCarrierName, appDeviceId, appSessionId, appEuk] = await Promise.all([
      TmapApp.getCarrierName(),
      TmapApp.getDeviceId(),
      TmapApp.getSessionId(),
      TmapApp.getEUK(),
    ])

    if (typeof appCarrierName === 'string' && /^[a-zA-Z0-9]+$/.test(appCarrierName)) {
      carrierName = appCarrierName
    } else {
      carrierName = 'ETC'
    }
    deviceId = appDeviceId
    sessionId = appSessionId
    euk = appEuk

    // 이후 API 전송 시 EUK값을 포함 할 수 있도록 Set.
    Cookies.set(COOKIE.EUK, euk)
  }

  mixpanel.init(CONFIG.MIXPANEL_ID, {
    debug: CONFIG.TARGET !== TARGET.PRD,
    ignore_dnt: CONFIG.TARGET !== TARGET.PRD,
    loaded: () => {
      mixpanel.identify(euk || '')
      mixpanel.register({
        $app_version: TmapApp.env.appVersion,
        $carrier: carrierName,
        $manufacterer: TmapApp.getDeviceServiceVendorId(),
        $model: model,
        $os_version: osVersion,
        device_id: deviceId,
        euk: euk || '',
        session_id: sessionId,
      })
      mixpanelInit = true
      mixpanelEventQueue.forEach((n) => {
        sendEvent(n.pageId, n.eventName, n.customs)
      })
      console.log('Mixpanel loaded')
    },
  })
}

const trackEvent = (pageId?: string, eventName?: string, customs?: {}) => {
  if (CONFIG.TARGET !== TARGET.PRD) {
    console.log(`[Mixpanel track] ${pageId}, ${eventName}, ${JSON.stringify(customs)}`)
  }
  mixpanel.track(`cs${pageId ? `:${pageId}` : ''}${eventName ? `:${eventName}` : ''}`, customs)
}

export function sendEvent(pageId?: string, eventName?: string, customs?: {}) {
  if (mixpanelInit) {
    trackEvent(pageId, eventName, customs)
  } else {
    mixpanelEventQueue.push({
      pageId,
      eventName,
      customs,
    })
  }
}
