import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import Router from 'next/router'

import { API } from '@/constants/api'
import { ROUTER } from '@/constants/router'
import { sendEvent } from '@/libs/mixpanel'

import { isClient } from '../compare'
import { setNewAccessKey } from './config'

const client = axios.create({
  timeout: API.CONFIG.DEFAULT_TIMEOUT,
})

const onRequestConfig = (config: InternalAxiosRequestConfig<any>) => config
const onSuccess = (response: AxiosResponse) => {
  const { headers } = response
  const newAccessKey = headers['new-accesskey']
  if (newAccessKey) {
    setNewAccessKey(newAccessKey)
  }
  return response?.data ?? response
}
const onRequestError = (error: AxiosError) => {
  return Promise.reject(error)
}
const onResponseErrorServer = async (error: AxiosError) => {
  return Promise.reject(error)
}
export const onResponseErrorClient = async (error: AxiosError<any>) => {
  return Promise.reject(error)
}

client.interceptors.request.use(onRequestConfig, onRequestError)
client.interceptors.response.use(
  onSuccess,
  isClient ? onResponseErrorClient : onResponseErrorServer
)

export const onErrorRouteAction = (error: AxiosError) => {
  if (error.response) {
    const { status } = error.response

    const query: Record<string, string> = {
      origin: window.location.href,
    }
    if (window.location.search.indexOf('isFirstHistory') > -1 || Router.pathname === ROUTER.MAIN) {
      query.isFirstHistory = 'true'
    }

    const eventName = error.config?.url ?? error.response?.config.url ?? status.toString()
    sendEvent('error', eventName, {
      pathname: window.location.pathname,
      status: status.toString(),
      error,
    })

    Router.replace({
      pathname: ROUTER.ERROR(status),
      query,
    })
  }
}

export type RequestCustomOption = {
  brand?: string
  isErrorActionDisabled?: boolean
}

export const request = async <T>(
  options: AxiosRequestConfig,
  customOption?: RequestCustomOption
): Promise<T> => {
  try {
    return await client.request(options)
  } catch (err: any) {
    if (!customOption?.isErrorActionDisabled) {
      onErrorRouteAction(err)
    }

    return Promise.reject(err)
  }
}
