import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { gte } from 'semver'

import { CONFIG } from '@/constants/config'

import { getQueryString, getSchemeJsonData } from './parse'

/**
 * 고객센터 내 다른 페이지를 새로운 웹뷰 레이어로 열어준다.
 */
// TODO: iOS에서 자기 자신의 서비스를 openServiceByName로 호출 시 새로운 웹뷰로 열리지 않고 현재 웹뷰가 갱신되는 현상이 발생하여 10.6.0 버전에 수정 예정
// https://tmobi.atlassian.net/browse/TMAPCLIENT-23590
export const openPageWebView = ({ pageId, extra }: { pageId: string, extra?: { [index: string]: string } }) => {
  const defaultExtra = {
    isFirstHistory: 'true',
  }
  const extraString = `&extra=${JSON.stringify(extra ? { ...extra, ...defaultExtra } : { ...defaultExtra })}`
  const queryString = `pageid=${pageId}${extraString}`
  TmapApp.openServiceByName({ serviceName: 'cs', jsonData: getQueryString(queryString) })
}
// export const movePage = ({ query }: { query?: { extra?: Object } | { [index: string]: string } }) => {
export const movePage = ({ pathname, query }: { pathname?: string, query?: { [index: string]: string } }) => {
  // const {
  //   extra,
  //   ...otherQuery
  // } = query || {}
  // let queryString = query ? `?${new URLSearchParams(otherQuery).toString()}` : ''
  // if (extra) {
  //   queryString = `${queryString}&extra=${JSON.stringify(extra)}`
  // }
  // TmapApp.openServiceByUrl({ url: `tmap://cs${queryString}` })
  const defaultQuery = {
    isFirstHistory: 'true',
  }
  const queryString = `?${new URLSearchParams(query ? {
    ...query,
    ...defaultQuery,
  } : { ...defaultQuery }).toString()}`
  TmapApp.openServiceByUrl({ url: `${CONFIG.HOST}${pathname ?? ''}${queryString}` })
}

/**
 * 스킴 주소를 openServiceByName으로 열어준다.
 */
export const openServiceByScheme = (scheme: string) => {
  if (TmapApp.env.isInApp) {
    const decodedScheme = decodeURIComponent(scheme)
    const isVerOpenServiceByName = gte(TmapApp.env.appVersion, '8.13.0')

    if (decodedScheme.indexOf('tmap://') > -1 && isVerOpenServiceByName) {
      TmapApp.openServiceByName({ serviceName: decodedScheme.split('?')[0].split('://')[1], jsonData: getSchemeJsonData(decodedScheme) })
    }
  }
}
