export const API = {
  CONFIG: {
    BASE_URL: process.env.NEXT_PUBLIC_APP_HOST ?? null,
    DEFAULT_TIMEOUT: 1000 * 100,
  },
  ENDPOINT: {
    FRONTMAN: process.env.NEXT_PUBLIC_FRONTMAN_ENDPOINT,
    UW_TMAP: process.env.NEXT_PUBLIC_UW_TMAP_ENDPOINT,
    ZENDESK: process.env.NEXT_PUBLIC_ZENDESK_ENDPOINT,
    SQUARE_MOCKUP: process.env.NEXT_PUBLIC_SQUARE_MOCKUP_ENDPOINT,
  },
  // client to server proxy prefix
  PREFIX_URL: {
    // 고객센터 도메인 /api path로 tmap-userweb-api 서버가 연결되게끔 되어있어서 BFF는 /bff-api 경로로 연결한다.
    USERWEB: '/bff-api',
  },
} as const

export const SQUARE_MOCKUP: Record<
  'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  Record<string, string>
> = {
  GET: {},
  POST: {
    '/requests': '/tmap-cs/requests',
  },
  PUT: {},
  DELETE: {},
  PATCH: {},
}
