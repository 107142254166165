import styled from '@emotion/styled'
import { PropsWithChildren, ReactNode, useMemo, useState } from 'react'

import Overlay from '@/components/Overlay'
import { COLOR } from '@/styles/color'
import { fontSize, touchGuide } from '@/styles/mixin'

import ConfirmContext, { ConfirmOption } from './ConfirmContext'

type ConfirmState = {
  content: ReactNode;
  okText: string;
  cancelText: string;
  onClickOK: () => void;
  onClickCancel: () => void;
};

interface ConfirmProps extends PropsWithChildren {}

function ConfirmProvider({ children }: ConfirmProps) {
  const [state, setState] = useState<ConfirmState>()

  const confirm = ({
    content,
    okText = '확인',
    cancelText = '취소',
  }: ConfirmOption): Promise<boolean> => {
    return new Promise((resolve) => {
      setState({
        content,
        okText,
        cancelText,
        onClickOK: () => {
          setState(undefined)
          resolve(true)
        },
        onClickCancel: () => {
          setState(undefined)
          resolve(false)
        },
      })
    })
  }

  const value = useMemo(() => ({ confirm }), [])

  return (
    <ConfirmContext.Provider value={value}>
      {children}
      {state && (
        <Overlay>
          <Container>
            <Modal>
              <Content>{state.content}</Content>
              <ActionGroup>
                <ButtonCancel
                  type="button"
                  onClick={state.onClickCancel}
                >
                  {state.cancelText}
                </ButtonCancel>
                <ButtonOk
                  type="button"
                  onClick={state.onClickOK}
                >
                  {state.okText}
                </ButtonOk>
              </ActionGroup>
            </Modal>
          </Container>
        </Overlay>
      )}
    </ConfirmContext.Provider>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`
const Modal = styled.div`
  width: 312px;
  min-height: 172px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);
  overflow: hidden;
`
const Content = styled.div`
  padding: 48px 20px;
  background: ${COLOR.gray.color.wb[0]};
  color: ${COLOR.gray.color.gray[900]};
  text-align: center;

  ${({ theme }) => fontSize(theme, 18)}
`
const ActionGroup = styled.div`
  display: flex;
`
const Button = styled.button`
  position: relative;
  flex: 1;
  padding: 13px 16px;

  ${({ theme }) => fontSize(theme, 16)}
  ${touchGuide()}
`
const ButtonCancel = styled(Button)`
  background: ${COLOR.gray.color.gray[200]};
  color: ${COLOR.gray.color.gray[900]};
`
const ButtonOk = styled(Button)`
  background: ${COLOR.primary.color.tmobi.blue[500]};
  color: ${COLOR.gray.color.wb[0]};
`

export default ConfirmProvider
