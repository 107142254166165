export type GrayColorType = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
export type PinkColorType = 100 | 200 | 300 | 400 | 500 | 600
export type BlueColorType = 100 | 200 | 300 | 400 | 500 | 600
export const COLOR = {
  gray: {
    color: {
      gray: {
        100: '#f8f9fa',
        200: '#eef0f3',
        300: '#e0e2e8',
        400: '#bec5cc',
        500: '#8c9299',
        600: '#6e747a',
        700: '#51565c',
        800: '#36393d',
        900: '#171819',
      } as Record<GrayColorType, string>,
      wb: {
        0: '#ffffff',
        1000: '#000000',
        transparent: {
          100: 'rgba(0, 0, 0, 0.04)',
          300: 'rgba(0, 0, 0, 0.1)',
          400: 'rgba(0, 0, 0, 0.2)',
          500: 'rgba(0, 0, 0, 0.3)',
          600: 'rgba(0, 0, 0, 0.4)',
          700: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
  },
  primary: {
    color: {
      tmobi: {
        blue: {
          100: '#f5f9ff',
          200: '#e6f1ff',
          300: '#cce0ff',
          400: '#3d8bff',
          500: '#0064ff',
          600: '#0052d1',
        },
      },
    },
  },
  secondary: {
    color: {
      tmobi: {
        green: {
          100: '#f1fbfb',
          200: '#dff6f5',
          300: '#beedec',
          400: '#2dccc6',
          500: '#00b2b2',
          600: '#038591',
        },
        pink: {
          100: '#fef7fc',
          200: '#ffe9f9',
          300: '#ffdbf4',
          400: '#ff70c8',
          500: '#fa32aa',
          600: '#c9388d',
        } as Record<PinkColorType, string>,
        purple: {
          100: '#f9f7ff',
          200: '#f1ebfe',
          300: '#e3d8fe',
          400: '#976cfb',
          500: '#773efa',
          600: '#5b2cc7',
        },
      },
      gradient: {
        a: 'linear-gradient( 92.97deg, #f043b0 20.31%,#773efa 100% )',
        b: 'linear-gradient( 93.3deg, #783cff 0%,#0064ff 100% )',
        c: 'linear-gradient( 92.95deg, #0064ff 10.94%,#34c7c2 100% )',
        skeleton: 'linear-gradient( 90deg, rgba(0, 0, 0, 0.1) 0%,rgba(0, 0, 0, 0.04) 100% )',
      },
    },
  },
  semantic: {
    color: {
      red: {
        100: '#fef7f6',
        200: '#fde8e4',
        500: '#f24724',
        600: '#a63019',
      },
      orange: {
        100: '#fffbf5',
        200: '#fff0d9',
        500: '#ff9000',
        600: '#b86e00',
      },
      yellow: {
        100: '#fffbf1',
        200: '#fff3d1',
        500: '#ffc219',
        600: '#d9a600',
      },
      green: {
        100: '#f3fcf6',
        200: '#daf5e5',
        500: '#25b856',
        600: '#1b853e',
      },
      evBlue: {
        100: '#f4fcfe',
        200: '#ddf5fb',
        500: '#00b2db',
        600: '#008cbf',
      },
    },
  },
  touch: {
    overlay: 'rgba(0, 0, 0, 0.1)', // transparent 300
  },
} as const
